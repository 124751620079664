import { Directive, forwardRef, inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { uuid } from '@bp/shared/utilities/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { IModalHostComponent } from './modal-host-component.interface';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

@Directive()
export abstract class ModalHostComponent extends Destroyable implements IModalHostComponent {

	protected readonly _modalService = inject(ModalService);

	readonly id: string = uuid();

	readonly panelClass?: string[];

	readonly disableClose?: boolean;

	@ViewChild(forwardRef(() => ModalComponent), { static: true })
	readonly modal!: ModalComponent;

	protected _dialogRef?: MatDialogRef<any>;

	constructor() {
		super();

		this.__findDialogRefByIdAndSetIt();
	}

	closeModal(): void {
		this._dialogRef?.close();
	}

	private __findDialogRefByIdAndSetIt(): void {
		this._modalService
			.getOpenModalById$(this.id)
			.pipe(takeUntilDestroyed(this))
			.subscribe(dialog => (this._dialogRef = dialog));
	}
}
